import { GroupContextValue } from './GroupContextValue';
import React from 'react';
import { GroupContextConsumer } from './GroupContext';

export interface WithGroupProps extends GroupContextValue {
  className?: string;
}

export const WithGroup = <P extends WithGroupProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof WithGroupProps>> => (props) => (
  <GroupContextConsumer>
    {(contextValue) => (
      <WrappedComponent {...props} {...(contextValue as any)} />
    )}
  </GroupContextConsumer>
);
