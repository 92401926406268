import React from 'react';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { st, classes } from './MemberCard.st.css';
import { MEMBER_NAME } from './hooks';
import { TextButton, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { Button } from '../../../../common/components/Button';
import { Loader } from '../../../../common/components/Loader/Loader';
import { Block, BlockAlign } from '../../../../common/components/Block';
import { Check } from '../../../../common/components/Icons/Check';
import { UserCard } from '../../../../common/components/Cards/UserCard';
import { Theme } from '../../../../common/context/theme';

export interface MemberCardProps {
  name: string;
  image: string | React.ReactNode;
  withDivider?: boolean;
  updating?: boolean;

  action?: React.ReactNode;
  done?: boolean;
  actionLabel?: string;
  actionDoneLabel?: string;
  allowSelect?: boolean;
  selected?: boolean;

  onActionClick?(): void;

  onSelect?(selected: boolean): void;
}

export const MemberCard: React.FC<MemberCardProps & Theme> = (props) => {
  const {
    action,
    onActionClick,
    name,
    image,
    done,
    actionDoneLabel,
    actionLabel,
    withDivider,
    updating,
    allowSelect,
    onSelect,
    selected,
    forceBlackAndWhite,
  } = props;

  function renderAction() {
    if (action) {
      return action;
    }

    if (!done) {
      const chars = Math.max(actionDoneLabel!.length, actionLabel!.length);
      return (
        <Button
          forceBlackAndWhite={forceBlackAndWhite}
          priority={PRIORITY.primary}
          onClick={onActionClick}
          className={classes.action}
          style={{ minWidth: `${chars + 1}ch` }}
          prefixIcon={updating ? <Loader /> : undefined}
          disabled={updating}
        >
          {actionLabel}
        </Button>
      );
    }
    return (
      <Block align={BlockAlign.center} className={classes.done}>
        <TextButton priority={TEXT_BUTTON_PRIORITY.primary}>
          <span className={st(classes.addButton, { bw: !!forceBlackAndWhite })}>
            {actionDoneLabel}
            <Check className={classes.check} />
          </span>
        </TextButton>
      </Block>
    );
  }

  return (
    <UserCard
      forceBlackAndWhite={forceBlackAndWhite}
      name={name}
      pic={image}
      dataHook={MEMBER_NAME}
      userAction={(onActionClick || action) && renderAction()}
      divider={withDivider}
      allowSelect={allowSelect}
      onSelect={onSelect}
      selected={selected}
    />
  );
};

MemberCard.displayName = 'MemberCard';
