import React from 'react';
import { Block, BlockFlow, BlockProps } from '../Block';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { PlaceholderTitle } from './Placeholders/PlaceholderCard';

import { st, classes } from './Card.st.css';
import { Theme } from '../../context/theme';

export interface CardTextProps extends Theme {
  subtitle?: string;
  title?: React.ReactNode;
  dataHook?: string;
  className?: string;
  reverse?: boolean;
  ellipsis?: boolean;
  withPlaceholder?: boolean;
}

export const CardText: React.FC<CardTextProps & BlockProps> = (props) => {
  const {
    subtitle,
    title,
    dataHook,
    reverse,
    ellipsis,
    withPlaceholder,
    forceBlackAndWhite,
    ...rest
  } = props;
  const subtitleText = subtitle && (
    <Text typography={TYPOGRAPHY.runningText} className={classes.subtitle}>
      {subtitle}
    </Text>
  );
  const placeholder = withPlaceholder && (
    <PlaceholderTitle className={classes.placeholderTitle}>
      {subtitleText}
    </PlaceholderTitle>
  );
  return (
    <Block
      flow={BlockFlow.row}
      {...rest}
      className={st(
        classes.root,
        { reverse, ellipsis, bw: forceBlackAndWhite } as any,
        rest.className,
      )}
    >
      <Text
        typography={TYPOGRAPHY.runningText}
        data-hook={dataHook}
        className={classes.title}
      >
        {title || placeholder}
      </Text>

      {subtitleText || placeholder}
    </Block>
  );
};

CardText.displayName = 'CardText';
