import React from 'react';
import { MembersActions } from '../../controllers/members/MembersActions';
import { PendingMembers } from '../../controllers/members/PendingMembers';
import { SiteMembersContext, SiteMembersWithActions } from './SiteMembers';
import { Questions } from '../../controllers/members/Questions';
import { Badges } from '../../controllers/members/Badges';

export interface WithSiteMembers
  extends MembersActions,
    Badges,
    SiteMembersWithActions,
    PendingMembers,
    Questions {}

export const withSiteMembers = <P extends WithSiteMembers>(
  WrappedComponent: React.ComponentType<P>,
) => (props: any) => {
  return (
    <SiteMembersContext.Consumer>
      {({ membersActions, ...restProps }: SiteMembersWithActions) => (
        <WrappedComponent {...props} {...membersActions} {...restProps} />
      )}
    </SiteMembersContext.Consumer>
  );
};
